<template>
	<div  style="background-color: black;width: 99.5vw;height: 99.1vh;border: red 5px solid">
		<div style="height: 6.1vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;border-bottom: red 5px solid; padding-bottom: 1%;
margin-top: 1%">
			{{ oper_name }} 人员总人数: {{ peo_count }}
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;border-bottom: red 5px solid;">
			<div style="border-right: red 5px solid;height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message1 }}
			</div>
			<div style="height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message2 }}
			</div>
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;border-bottom: red 5px solid;">
			<div style="border-right: red 5px solid;height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message3 }}
			</div>
			<div style="height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message4 }}
			</div>
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;border-bottom: red 5px solid;">
			<div style="border-right: red 5px solid;height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message5 }}
			</div>
			<div style="height: 14vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14vh">
				{{ peo_message6 }}
			</div>
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;border-bottom: red 5px solid;">
			<div style="border-right: red 5px solid;height: 14.5vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14.5vh">
				{{ peo_message7 }}
			</div>
			<div style="height: 14.5vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 14.5vh">
				{{ peo_message8 }}
			</div>
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;border-bottom: red 5px solid;">
			<div style="border-right: red 5px solid;height: 15vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 15vh">
				{{ peo_message9 }}
			</div>
			<div style="height: 15vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 15vh">
				{{ peo_message10 }}
			</div>
		</div>

		<div style="display: grid; grid-template-columns: 1fr 1fr;">
			<div style="border-right: red 5px solid;height: 15vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 15vh">
				{{ peo_message11 }}
			</div>
			<div style="height: 15vh;color: red;font-size: 50px;font-weight: bolder;text-align: center;line-height: 15vh">
				{{ peo_message12 }}
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			dateStr : '',
			oper_name: '',
			peo_count: '',
			peo_result: [],
			peo_message1: '',
			peo_message2: '',
			peo_message3: '',
			peo_message4: '',
			peo_message5: '',
			peo_message6: '',
			peo_message7: '',
			peo_message8: '',
			peo_message9: '',
			peo_message10: '',
			peo_message11: '',
			peo_message12: ''
		}
	},
	created() {
		this.dateStr = setNowDate(new Date())
		this.oper_name = this.$route.params.opername
		//this.dateStr = '2023-10-25'
		this.queryPeoGpsIn()
		setInterval(() => {
			this.queryPeoGpsIn()
		}, 60000 * 5)
	},
	methods: {
		queryPeoGpsIn(){
			this.axios.get2('/peopleGpsBasal/showPeoData', (response) => {
				this.peo_count = response.obj
				this.peo_message1 = response.obj2[0]
				this.peo_message2 = response.obj2[1]
				this.peo_message3 = response.obj2[2]
				this.peo_message4 = response.obj2[3]
				this.peo_message5 = response.obj2[4]
				this.peo_message6 = response.obj2[5]
				this.peo_message7 = response.obj2[6]
				this.peo_message8 = response.obj2[7]
				this.peo_message9 = response.obj2[8]
				this.peo_message10 = response.obj2[9]
				this.peo_message11 = response.obj2[10]
				this.peo_message12 = response.obj2[11]
			},{
				bdst: this.oper_name,
				dateStr: this.dateStr
			})
		}
	}
}
</script>

<style>

</style>